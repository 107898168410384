<template>
  <div class="crop-type">
    <PageTop
      :searchShow="true"
      :switchShow="true"
      placeholderTxt="农资分类配置"
      @addMeans="addMeans"
      @getSearch="getSearch"
    >
      <div class="adTitle" slot="adTitle">农资分类配置</div>
      <p slot="addName">新增分类</p>
    </PageTop>
    <div class="table-box">
      <MyTable
        class="animated fadeInUp"
        :tableData="tableData"
        :configTable="configTable"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
      ></MyTable>
    </div>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="600px"
      :before-close="setClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ popTitle }}
        </div>
        <div class="add-form">
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            hide-required-asterisk
          >
            <el-form-item
              label="标题"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input
                v-model.trim="form.name"
                autocomplete="off"
                placeholder="请输入标题"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="启用"
              :label-width="formLabelWidth"
              prop="custom_state"
            >
              <el-switch class="my-switch" v-model="form.custom_state">
              </el-switch>
            </el-form-item>

            <el-form-item label=" " class="dialog-footer">
              <el-button class="ok-button" type="primary" @click="okAdd('form')"
                >确 定</el-button
              >
              <el-button class="no-button" @click="noAdd('form')"
                >取 消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getResourceClassListApi,
  getAddResourceClassApi,
  postEditResourceClassApi,
  getResourceClassInfoApi,
  delResourceClassApi,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  components: {
    PageTop: () => import("@/components/PageTop.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
  },
  data() {
    return {
      addShow: false,
      // 表格
      configTable: [
        {
          prop: "name",
          label: "分类名称",
          width: "128",
        },
        {
          prop: "custom_state",
          label: "状态",
          width: "109",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "",
        },
        {
          prop: "updated_time",
          label: "最近修改时间",
          width: "",
        },
        {
          prop: "",
          label: "操作",
          width: "442",
          editType: true,
          delType: true,
        },
      ],
      tableData: [],
      // 弹窗
      popTitle: "",
      formLabelWidth: "100px",
      // 农资ID
      id: "",
      // 农资详情/添加表单
      form: {
        name: "",
        custom_state: 0,
      },
      // 表单校验规则
      rules: {
        name: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getResourceClassListApi();
  },
  methods: {
    setClose() {
      this.addShow = false;
      this.form = {
        name: "",
        custom_state: 0,
      };
    },
    // 获取农资分类详情
    async getResourceClassInfoApi(id) {
      const { code, results } = await getResourceClassInfoApi({
        class_id: id,
      });
      if (code === 0) {
        this.form = results;
        this.form.custom_state = this.form.custom_state ? true : false;
      }
    },
    // 获取农资分类列表
    async getResourceClassListApi() {
      showFullScreenLoading();
      const { code, results } = await getResourceClassListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.tableData = results.data;
        this.tableData.forEach((item) => {
          if (item.custom_state) {
            item.custom_state = "启用";
          } else {
            item.custom_state = "禁用";
          }
        });
      }
      tryHideFullScreenLoading();
    },
    // 删除农资分类
    handleDelete({ id }) {
      this.$confirm("此操作将删除该农资分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code } = await delResourceClassApi({
            class_id: id,
          });
          if (code === 0) {
            this.$notify({
              title: "成功",
              message: "已删除",
              type: "success",
            });
            this.getResourceClassListApi();
          }
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    // 禁用农资分类
    handleForbidden() {
      this.$confirm("此操作将禁用该农资分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$notify({
            title: "成功",
            message: "已禁用",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    // 启用农资分类
    handleEnable() {
      this.$notify({
        title: "成功",
        message: "已启用",
        type: "success",
      });
    },
    // 编辑农资分类
    handleEdit({ id }) {
      this.id = id;
      this.getResourceClassInfoApi(id);
      this.popTitle = "编辑分类";
      this.addShow = true;
    },
    getSearch() {},
    // 添加农资分类-按钮
    addMeans() {
      this.popTitle = "新增分类";
      this.addShow = true;
    },
    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.submitForm();
      });
    },
    async submitForm() {
      let custom = this.form.custom_state ? 1 : 0;
      if (this.popTitle === "新增分类") {
        const { code } = await getAddResourceClassApi({
          custom_state: custom,
          name: this.form.name,
          manor_id: this.farmID,
        });
        if (code === 0) {
          this.$notify({
            title: "成功",
            message: "已添加",
            type: "success",
          });
          this.getResourceClassListApi();
          this.$refs["form"].resetFields();
          this.addShow = false;
        }
      } else {
        const { code } = await postEditResourceClassApi({
          custom_state: custom,
          name: this.form.name,
          manor_id: this.farmID,
          class_id: this.id,
        });
        if (code === 0) {
          this.getResourceClassListApi();
          this.$notify({
            title: "成功",
            message: "已修改",
            type: "success",
          });
          this.$refs["form"].resetFields();
          this.addShow = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.crop-type {
  // flex: 1;
  width: calc(100% - 450px);

  display: flex;
  flex-direction: column;
  .land-admin {
    margin: 0 0 16px 0;
  }
  .table-box {
    width: 100%;
    padding: 38px 49px;
    background-color: #0e2138;
  }
  .big-pop {
    /deep/.el-dialog {
      width: 600px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
    /deep/.el-form-item {
      margin-bottom: 32px;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      margin-top: 40px;
      padding: 0 0 0 100px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
    .big-main {
      width: 600px;
      height: 624px;
      background: url(../../assets/image/configure/crop/bgimg.png) no-repeat
        center center;
      background-size: cover;
      padding: 45px 55px 0 55px;

      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }
      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }
}
</style>
